import React, { useState, useEffect } from 'react';
import "./GroupList.css";
import GroupListItem from './../GroupListItem/GroupListItem';

const GroupList: React.FC = () => {
  // Пример массива данных о чатах с изображениями
  const [groups, setGroups] = useState([
    // { id: 1, name: 'Chat 1', imageUrl: 'https://sadlovestory.ru/api/v1/attachment/images/6?signature=xB8N9gjNouTBdwuxKlY7VB99ce6q46BELa7uFcu43Uo%3D&expires=1734992913103&size=200x200' },
    // { id: 2, name: 'Chat 2', imageUrl: 'https://sadlovestory.ru/api/v1/attachment/images/6?signature=xB8N9gjNouTBdwuxKlY7VB99ce6q46BELa7uFcu43Uo%3D&expires=1734992913103&size=200x200' },
    // { id: 3, name: 'Chat 3', imageUrl: 'https://sadlovestory.ru/api/v1/attachment/images/6?signature=xB8N9gjNouTBdwuxKlY7VB99ce6q46BELa7uFcu43Uo%3D&expires=1734992913103&size=200x200' },
  ]);

  return (
    <div className="group-list-container">
      {/*{groups.map(group => (*/}
      {/*  // <GroupListItem key={group.id} name={group.name} imageUrl={group.imageUrl} />*/}
      {/*))}*/}
    </div>
  );
};

export default GroupList;
