import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store'; 
import "./HomePage.css"; 
import FriendsNav from '../../components/friendsNav/friendsNav';
import Profile from '../../components/Profile/Profile';
import { useGetUserProfileQuery } from '../../services/profileApi';
import ChatsList from '../../components/chatsList/chatsList';
import Modal from 'react-modal'; 
import CreateChatModal from '../../components/createChatModal/createChatModal';
import ListMessages from '../../components/listMessages/listMessages';
import WebSocketManager from '../../slices/WebSocketManager';
import { clearList} from '../../slices/messagesListSlice';
import GroupList from './../../components/GroupList/GroupList';
const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated); // Проверка авторизации
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна
  const [isSocketConnected, setIsSocketConnected] = useState(false); // Состояние для подключения сокета
  const chatid = useSelector((state: RootState) => state.message.chat_id);
   const profileData = useSelector((state: RootState) => state.profile);
   const callDataT = useSelector((state: RootState) => state.message.callData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login'); 
    }
    dispatch(clearList())
  }, [isAuthenticated, navigate, dispatch]);

  useEffect(() => {
    setIsSocketConnected(false);
    const connectWebSocket = async () => {
      try {
        setIsSocketConnected(await WebSocketManager.connect('/ws', {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }));
     // Успешное подключение
      } catch (error) {
        console.error('Error connecting WebSocket:', error);
        setIsSocketConnected(false); // Подключение не удалось
      }
    };

    connectWebSocket();

    return () => {
      setIsSocketConnected(false);
      WebSocketManager.close();
    };

  }, []); 

  if (!isAuthenticated || !isSocketConnected) {
    return <div>Загрузка приложения...</div>; 
  }

  const handleOpenModal = () => {
    setIsModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  return (
    <div className="main__wrapper">
      <div className="channels__wrapper">
        <button className="sidebar-button"></button>
        <GroupList  />
      </div>

      <div className="content">
        <FriendsNav />
        <div className="content__wrapper">
          <div className="content__left">
            <div className="content__left-profile">
              <div className="chats__add">
                <h1 className="ctats__title">DIRECT MESSAGES</h1>
                <button className="chats__create" onClick={handleOpenModal}></button> {/* Кнопка для открытия модального окна */}
              </div>
              <div className="chats__list">
                <ChatsList />
              </div>
              <Profile />
            </div>
          </div>
          <div className="content__right">
            {(chatid !== "") &&  <ListMessages />}
          
          </div>
        </div>
      </div>

      <CreateChatModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default HomePage;
