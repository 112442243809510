// profileApi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ProfileResponse } from '../components/interfaces';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/user',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserProfile: builder.query<ProfileResponse, void>({
      query: () => 'profile',
      transformResponse: (response: any) => {
        console.log('API Response:', response); // Логируем ответ API
        return response;
      },
    }),
    updateUserProfileFields: builder.mutation<ProfileResponse, { apiEnd: string, obj: Object}>({
      query: ({apiEnd, obj}) => ({
        url: `${apiEnd}`,  
        method: 'PATCH',
        body: obj,  
      }),
      transformResponse: (response: any) => {
        console.log('Updated API Response:', response);  // Логируем ответ API
        return response;
      },
    }),
    getUserProfileById: builder.query<ProfileResponse, {id:string}>({
      query: ({id}) => `profile/byId/`+id,
      transformResponse: (response: any) => {
        console.log('API Response:', response); // Логируем ответ API
        return response;
      },
    }),
    getUserProfileByName: builder.query<ProfileResponse, {username:string}>({
      query: ({username}) => `profile/${username}`,
      transformResponse: (response: any) => {
        console.log('API Responsedfdf:', response); // Логируем ответ API
        return response;
      },
    }),
  }),
});


export const { useGetUserProfileQuery, useUpdateUserProfileFieldsMutation, useGetUserProfileByIdQuery, useLazyGetUserProfileByIdQuery, useGetUserProfileByNameQuery, useLazyGetUserProfileByNameQuery } = profileApi;
