import React, { useState, useEffect } from 'react';
import { useGetPrivateChatsQuery } from '../../services/chatsApi'; 
import Chats from './../chats/chats'; 
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PrivateContactItemProps } from './../chats/chats';
import { useLazyGetUserProfileByIdQuery, useLazyGetUserProfileByNameQuery } from '../../services/profileApi';
import WebSocketManager from '../../slices/WebSocketManager';
import './chatsList.css';
import { addChat, Chat, removeChat, setChatList, updateChatLastMessage } from '../../slices/chatListSlice';
import { addMessage, Message, setChatID } from '../../slices/messagesListSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ChatEvent {
  chat_id: number;
  message: string;
  target_user_id: number;
  type: string;
}

const ChatsList: React.FC = () => {
  const chats = useSelector((state: RootState) => state.chat);
  const [pageIndex, setPageIndex] = useState(0); 
  const { data: apiResponse, refetch } = useGetPrivateChatsQuery({ pageIndex, pageSize: 10 });
  const [fetchUserProfile] = useLazyGetUserProfileByIdQuery();
  const [fetchUserProfile2] = useLazyGetUserProfileByNameQuery();
  const profile = useSelector((state: RootState) => state.profile);
  const [loading, setLoading] = useState(false);
  const chatid = useSelector((state: RootState) => state.message.chat_id);
  const chatIdRef = React.useRef(chatid);
  const [user, setUser] = useState<string | null>(null)

  const dispatch = useDispatch();
 useEffect(() => {
    chatIdRef.current = chatid;
  }, [chatid]);
  useEffect(() => {

    refetch()
  }, [refetch])
  useEffect(() => {
    if (apiResponse) {
      // Маппируем чаты с API
      
      const mappedChats: Chat[] = apiResponse.map((chat) => ({
        chat_id: chat.chat_id,
        chat_name: chat.chat_name,
        is_group_chat: chat.is_group_chat,
        last_chat_message: chat.last_chat_message,
        chat_image_url: chat.chat_image_url,
        companion_username: chat.companion_username
      }));
      // Сортируем чаты по времени последнего сообщения (по убыванию)
      const sortedChats = mappedChats.sort((a, b) => {
        if (a.last_chat_message && b.last_chat_message) {
          return new Date(b.last_chat_message.message_date).getTime() - new Date(a.last_chat_message.message_date).getTime();
        }
        return 0;
      });
        console.log("ghghghghghh")
      // Обновляем список чатов в Redux
      dispatch(setChatList(sortedChats));
    }
  }, [apiResponse, dispatch, refetch]);

  useEffect(() => {
    const handleChatEvent = async (event: ChatEvent) => {
      if (event.type === 'CREATED' && event.target_user_id != null) {
        // Личный чат
        try {
          const user = await fetchUserProfile({ id: event.target_user_id.toString() }).unwrap();
          setUser(event.target_user_id.toString())
          dispatch(addChat(
            {
              chat_id: event.chat_id,
              chat_name: user.username,
              is_group_chat: false, // укажите правильное значение
              last_chat_message: null, // устанавливайте правильное сообщение
              chat_image_url: user.user_img_200x200_url,
              companion_username: user.username, // установите правильное имя
            },
          ));
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      } else if (event.type === 'CREATED' && event.target_user_id == null) {
        // Групповой чат
      } else if (event.type === 'DELETED') {
        // Удаление чата
        dispatch(removeChat(event.chat_id));
        
      }
      refetch();
    };



    if (profile.id) {
      WebSocketManager.subscribe(`/topic/${profile.id}/chat-events`, (message: any) => {
        const chatEvent: ChatEvent = JSON.parse(message.body);
        handleChatEvent(chatEvent);
      });
    }
    return () => {
      WebSocketManager.unsubscribe(`/topic/${profile.id}/chat-events`);
      console.log(`Unsubscribed from topic: ${`/topic/${profile.id}/chat-events`}`);
    };
  }, [profile, fetchUserProfile]);

  const handleMessageCreated = async (event: Message) => {
    if (Number(chatIdRef.current) === event.chat_id) {
      dispatch(addMessage(event));
      console.log(event.forward_message)
    }
    dispatch(updateChatLastMessage({ chatId: event.chat_id, lastMessage: event }));
    if (event.sender.id !== profile.id) { 
      if (event.message_text) {
        toast.info(
          <>
            <strong>{event.sender.first_name + " " + event.sender.last_name}:</strong> {event.message_text}
          </>,
          {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }

      else if (event.attachments && event.attachments.length > 0) {
        const fileName = event.attachments[0].attachment_type; 
        toast.info(
          <>
            <strong>{event.sender.first_name + " " + event.sender.last_name}:</strong> отправил файл: {fileName}
          </>,
          {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    }
  };
  
 useEffect(() => {
    if (profile.id) {
      const topic = `/topic/${profile.id.toString()}/newMessages`;
      WebSocketManager.subscribe(topic, (message: any) => {
        const messEvent: Message = JSON.parse(message.body);
        handleMessageCreated(messEvent);
      });
    

      return () => {
        WebSocketManager.unsubscribe(topic);
        console.log(`Unsubscribed from ${topic}`);
      };
    }
    refetch();
  }, [profile.id]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const bottom =
      e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.clientHeight;
    if (bottom && !loading) {
      setLoading(true); 
      setPageIndex((prevPageIndex) => prevPageIndex + 1); 
    }
  };

  return (
    <div className="contacts-list-container" onScroll={handleScroll}>
      <ToastContainer /> {/* Контейнер для уведомлений */}
      <div className="contacts-list">
        {chats.map((chat) => (
          <Chats id={chat.chat_id} imageUrl={chat.chat_image_url? chat.chat_image_url: ""} name={chat.chat_name} lastMessage={chat.last_chat_message} key={chat.chat_id}/>
        ))}
      </div>
      {loading && <div>Loading more contacts...</div>} 
    </div>
  );
};

export default ChatsList;
