import React, { useEffect, useState } from 'react';
import './listMessages.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { addMessageReaction, deleteMessageReaction, setMessageList } from '../../slices/messagesListSlice';
import WebSocketManager from '../../slices/WebSocketManager';
import { useGetChatMessagesQuery, useAddReactionOnMessageMutation } from '../../services/chatsApi';
import MessageC from '../message/message';
import VideoContainer from '../../components/VideoContaider/videoContainer';
import { useSaveAttachmentMutation } from '../../services/attachmentApi';
import { useGetUserProfileByIdQuery, useLazyGetUserProfileByIdQuery } from '../../services/profileApi';

interface Attachment {
  attachment_id: number;
  attachment_type: string;
}
const ListMessages: React.FC<any> = ({callDat}) => {
  const messages = useSelector((state: RootState) => state.message);
  const chats = useSelector((state: RootState) => state.chat);
  const chatid = useSelector((state: RootState) => state.message.chat_id);
  const avatar = useSelector((state: RootState) => state.message.chat_image);
  const callData = useSelector((state: RootState) => state.message.callData);
  const profileData = useSelector((state: RootState) => state.profile);
  const [saveAttachment] =  useSaveAttachmentMutation();
  const [addReactionOnMessage] = useAddReactionOnMessageMutation();
  const [messageInput, setMessageInput] = useState('');
  const [forwardId, setForwardId] = useState<number|null>(null);
  const [attachmentsPrev, setAttachmentsPrev] = useState<[string, File][]>([]); // Храним пары (preview, file)
  const [fetchUserProfile] = useLazyGetUserProfileByIdQuery();
  // Стейт для вложений
  const dispatch = useDispatch();
  const { data: apiResponse, refetch } = useGetChatMessagesQuery({ chatId: chatid });
  const chatIdRef = React.useRef(chatid);
  

  const [isCallActive, setIsCallActive] = useState(false);
 

  useEffect(() => {
    if (apiResponse && Array.isArray(apiResponse)) {
      const reversedMessages = [...apiResponse].reverse();
      dispatch(setMessageList(reversedMessages));
    }
  }, [apiResponse, chatid]);


  // Обновляем ссылку при изменении chatid
  useEffect(() => {
    chatIdRef.current = chatid;
    refetch();
  }, [chatid]);

 

  const handleReactionChanged = async (event: { message_id: number; user_id: number; reaction_code: number }) => {
    const { message_id, user_id, reaction_code } = event;
    const message = messages.messages.find((msg) => msg.message_id === message_id);
    const existingReaction = message?.reactions?.find((reaction) => reaction.user_id === user_id);
    if(existingReaction){
      dispatch(
        deleteMessageReaction({
          messageId: message_id,
          userId: user_id, 
        })
      );   
    }
    else{
      dispatch(
        addMessageReaction({
          messageId: message_id,
          reaction: {
            user_id: user_id,
            reaction_code: reaction_code, 
          },
        })
      );
    }
  
  
    // Обновляем данные с сервера
    refetch();
  };
  
  useEffect(() => {
    if (profileData.id) {
      const topic2 = `/topic/chat/${chatid}/reactions`;


      WebSocketManager.subscribe(topic2, (message: any) => {
        const messEvent: { message_id: number; user_id: number; reaction_code: number } = JSON.parse(message.body);
        handleReactionChanged(messEvent);
      });

      return () => {
        WebSocketManager.unsubscribe(topic2);
        console.log(`Unsubscribed from ${topic2}`);
      };
    }
    refetch();
  }, [profileData.id]);

  useEffect(() => {
    if (apiResponse && Array.isArray(apiResponse)) {
      console.log("API Response:", apiResponse); // Убедитесь, что данные корректны
      const reversedMessages = [...apiResponse].reverse();
      dispatch(setMessageList(reversedMessages));
    }
    refetch()
  }, [chatid]);
  

  const handleSendMessage = async (): Promise<void> => {
    try {
      if (messageInput || attachmentsPrev.length!= 0 || forwardId != null) {
        // Загружаем все вложения на сервер
        const attachmentPromises = attachmentsPrev.map(async ([preview, file]) => {
          const form = new FormData();
          form.append('file', file);
          form.append('type', 'IMAGES'); // Тип файла
  
          try {
            const response = await saveAttachment({ form });
            if (response.data) {
              return {
                attachment_id: response.data,
                attachment_type: 'IMAGES',
              };
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        });
  
        const uploadedAttachments = await Promise.all(attachmentPromises);
  
        // Отправляем сообщение с вложениями
        WebSocketManager.send(`/app/chat/${chatid}/sendMessage`, {
          text: messageInput,
          forward_id: forwardId,
          is_audio: false,
          attachments: uploadedAttachments.filter(Boolean), // Отфильтровываем неудачные загрузки
        });
  
        // Очищаем состояния после отправки
        setForwardId(null)
        setMessageInput('');
        setAttachmentsPrev([]);  // Очищаем вложения
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  
  const handleCallClick = () => {
    if (messages.chat_id) {
      setIsCallActive(true);
    }
  };

  function handleAddReaction(mes_id: number, reac_code: string){
    addReactionOnMessage({chat_id:chatid, mes_id: mes_id, reac_code: reac_code  /*'\u{1F600}'.codePointAt(0) ?? 0 */})
  };
  function handleDeleteReaction(mes_id: number){ 
    addReactionOnMessage({chat_id:chatid, mes_id: mes_id, reac_code: null  /*'\u{1F600}'.codePointAt(0) ?? 0 */})
  };
  function handleSetForward(mes_id: number){
    setForwardId(mes_id)
  };

  const handleSetAttachment = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*'; // Только изображения
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files[0]) {
        const file = target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            // Добавляем пару (preview, file)
            setAttachmentsPrev((prevAttachments) => [
              ...prevAttachments,
              [reader.result as string, file], // Сохраняем Data URL и файл
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click(); // Открываем файловый диалог
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachmentsPrev((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };
  return (
    
    <div className="list-messages">
      <div className="list-messages__header">
        <div className="list-messages__interlocutor">
          <img className="list-messages__avatar" src={avatar} alt="" />
          <span className="list-messages__name"></span>
        </div>

        <div className="list-messages__buttons">
          <button className="call" onClick={handleCallClick}></button>
          <button className="list-messages__choice"></button>
        </div>
      </div>
      <div className="message__wrapper">
        {messages.messages.map((mes) => (
          <MessageC
            id={mes.message_id}
            key={mes.message_id}
            content={mes.message_text}
            date={mes.message_date}
            isUserMessage={mes.sender.id === profileData.id}
            avatarUrl={mes.sender.image_url}
            username={`${mes.sender.first_name} ${mes.sender.last_name[0]}`}
            attachments={mes.attachments}
            reactions={mes.reactions}
            forward_message={mes.forward_message}
            addReaction={handleAddReaction}
            deleteReaction={handleDeleteReaction}
            setAsForward={handleSetForward}
          />
        ))}
      </div>

      <div className="attached">
        {attachmentsPrev.map(([preview], index) => (
          <div key={index} className="attachment-thumbnail">
            <img src={preview} alt="attachment" />
            <button onClick={() => handleRemoveAttachment(index)}>×</button>
          </div>
        ))}
        {forwardId && (
        <div className="attachment-thumbnail">
          <span>Forwarded Message</span> 
          <button onClick={() => setForwardId(null)}>×</button>
        </div>
        )}
      </div>

      <div className="list-messages__footer">
        <button className="list-messages__upload" onClick={handleSetAttachment}></button>
        <input
          className="list-messages__input"
          type="text"
          placeholder="Введите сообщение..."
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <button className="list-messages__send" onClick={handleSendMessage}></button>
      </div>

      {isCallActive && messages.chat_id !== '' && (
        <VideoContainer chat_Id={chatid} />
      )}
    </div>

  );
};

export default ListMessages;
