import React, { useEffect, useRef, useState } from 'react';
import './videoContainer.css';
import WebSocketManager from '../../slices/WebSocketManager';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useGetUserProfileByIdQuery } from '../../services/profileApi';

interface VideoContainerProps {
  chat_Id: string;
}

const VideoContainer: React.FC<VideoContainerProps> = ({ chat_Id }) => {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [isCallStarted, setIsCallStarted] = useState(false);
  const [callStatus, setCallStatus] = useState<'waiting' | 'ongoing' | null>(null); // Статус звонка
  const profile = useSelector((state: RootState) => state.profile);
  const userId = useSelector((state: RootState) => state.message.user_id);
  const { data: fetchUserProfile } = useGetUserProfileByIdQuery({ id: userId ? userId : '' });
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);

  useEffect(() => {
    WebSocketManager.subscribe(`/topic/${profile.id}/calls`, async (message: any) => {
      try {
        const peerConnection = peerConnectionRef.current;
        if (!peerConnection) return;

        const data = JSON.parse(message.body);

        if (data.type === 'offer') {
          const remoteDesc = new RTCSessionDescription({
            type: 'offer',
            sdp: data.payload.sdp,
          });
          await peerConnection.setRemoteDescription(remoteDesc);

          const answer = await peerConnection.createAnswer();
          await peerConnection.setLocalDescription(answer);

          WebSocketManager.send(`/app/chat/${chat_Id}/call`, {
            type: 'answer',
            to_user_id: userId,
            chat_id: null,
            payload: {
              sdp: answer.sdp,
            },
          });

          setCallStatus('ongoing');
        }

        if (data.type === 'answer') {
          const remoteDesc = new RTCSessionDescription({
            type: 'answer',
            sdp: data.payload.sdp,
          });
          await peerConnection.setRemoteDescription(remoteDesc);
          setCallStatus('ongoing');
        }

        if (data.type === 'candidate') {
          const candidate = new RTCIceCandidate(data.payload);
          await peerConnection.addIceCandidate(candidate).catch((err) => {
            console.error('Ошибка при добавлении ICE-кандидата:', err);
          });
        }
      } catch (error) {
        console.error('Ошибка при обработке сообщения WebRTC:', error);
      }
    });

    return () => {
      WebSocketManager.unsubscribe(`/topic/${profile.id}/calls`);
    };
  }, [chat_Id, profile.id, userId]);

  const startCall = async () => {
    try {
      const configuration = {
        iceServers: [
          {
            urls: 'turn:94.228.169.5',
            username: 'testuser',
            credential: 'testpassword',
          },
        ],
      };

      const peerConnection = new RTCPeerConnection(configuration);
      peerConnectionRef.current = peerConnection;

      // Получаем локальный поток аудио и видео
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      setLocalStream(stream);

      // Добавляем локальный поток в PeerConnection
      stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));

      // Обработка входящего потока
      const remoteStream = new MediaStream();
      setRemoteStream(remoteStream);

      peerConnection.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => remoteStream.addTrack(track));
      };

      peerConnection.onicecandidate = async (event) => {
        if (event.candidate) {
          try {
            await WebSocketManager.send(`/app/chat/${chat_Id}/call`, {
              type: 'candidate',
              to_user_id: userId,
              chat_id: null,
              payload: {
                candidate: event.candidate.candidate,
                sdpMid: event.candidate.sdpMid,
                sdpMLineIndex: event.candidate.sdpMLineIndex,
              },
            });
          } catch (error) {
            console.error('Ошибка при отправке кандидата:', error);
          }
        }
      };

      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      WebSocketManager.send(`/app/chat/${chat_Id}/call`, {
        type: 'offer',
        to_user_id: userId,
        chat_id: null,
        payload: { sdp: offer.sdp },
      });

      setCallStatus('waiting');
      setIsCallStarted(true);
    } catch (error) {
      console.error('Ошибка при создании WebRTC-соединения:', error);
    }
  };

  const endCall = () => {
    if (peerConnectionRef.current) {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
        setLocalStream(null);
      }
      peerConnectionRef.current.close();
      setRemoteStream(null);
      setIsCallStarted(false);
      setCallStatus(null);
    }
  };

  return (
    <div className="call-component">
      <div className="video-container">

        {localStream && (
          <video
            className="local-video"
            ref={(ref) => ref && (ref.srcObject = localStream)}
            autoPlay
            playsInline
            muted
          />
        )}
      
        {remoteStream && (
          <video
            className="remote-video"
            ref={(ref) => ref && (ref.srcObject = remoteStream)}
            autoPlay
            playsInline
          />
        )}
      </div>

      {callStatus === 'waiting' && <p style={{ color: 'white' }}>Ожидается принятие звонка...</p>}
      {callStatus === 'ongoing' && <p style={{ color: 'white' }}>Звонок в процессе...</p>}

     
      {!isCallStarted ? (
        <button onClick={startCall} className="start-call-button">
          Начать звонок
        </button>
      ) : (
        <button onClick={endCall} className="end-call-button">
          Завершить звонок
        </button>
      )}
    </div>
  );
};

export default VideoContainer; 

