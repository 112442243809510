import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Message {
    sender: Sender
    attachments: Attachment[] | null
    reactions: Reaction[] | null
    message_id:number
    chat_id: number
    message_text: string
    message_date: string
    forward_id: number | null
    forward_message: Message | null
    is_changed: boolean
    is_audio: boolean
    is_pinned: boolean
}

export interface Sender {
    id: number
    first_name: string
    last_name: string
    image_url: string
    is_active: boolean
}
export interface Attachment {
    name: string
    attachment_type: string
    attachment_id: number
    attachment_url: string
    attachment_image_small_url: string
    attachment_image_big_url: string
    creator_id: number
    creation_date: string
}
export interface Reaction {
    user_id: number
    reaction_code: number
}

export interface CallData {
  SDP: any;
  ICE: any[];
}


export interface MessageState {
    messages: Message[];
    chat_id: string; 
    chat_image: string | undefined;
    user_id: string | null;
    callData: CallData;
    
}

const initialState: MessageState = {
    messages: [],
    chat_id:"" ,
    chat_image:"",
    user_id: null,
    callData: {
      SDP: null,
      ICE: []
    }
  };


const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
      // Устанавливает список сообщений
      setMessageList: (state, action: PayloadAction<Message[]>) => {
        state.messages = action.payload;
      },
      setChatID: (state, action: PayloadAction<string>) => {
        state.chat_id = action.payload
        console.log("AP2:", state.chat_id);
      },
      setUserID: (state, action: PayloadAction<string>) => {
        state.user_id = action.payload
      },
      setChatImage: (state, action: PayloadAction<string | undefined>) => {
        state.chat_image = action.payload
      },
      /*
      setCallDataSDP: (state, action: PayloadAction<any>) => {
        state.callData.SDP = action.payload
      },
      
      clearCallData: (state) => {
        state.callData = {
          SDP: null,
          ICE: []
        }
      },

      addCallDataICE: (state, action: PayloadAction<any>) => {
        state.callData.ICE.push(action.payload)
      },
    */

      // Добавляет новое сообщение
      addMessage: (state, action: PayloadAction<Message>) => {
        state.messages.push(action.payload);
      },

      deleteMessageReaction: (state, action: PayloadAction<{ messageId: number; userId: number }>) => {
        const { messageId, userId } = action.payload;
        const message = state.messages.find((msg) => msg.message_id === messageId);
        if (message && message.reactions) {
          message.reactions = message.reactions.filter((reaction) => reaction.user_id !== userId);
        }
      },
  
      // Добавляет реакцию к сообщению
      addMessageReaction: (state, action: PayloadAction<{ messageId: number; reaction: Reaction }>) => {
        const { messageId, reaction } = action.payload;
        const message = state.messages.find((msg) => msg.message_id === messageId);
        if (message) {
          if (!message.reactions) {
            message.reactions = [];
          }
          message.reactions.push(reaction);
        }
      },
      // Удаляет сообщение по его ID
      deleteMessage: (state, action: PayloadAction<number>) => {
        state.messages = state.messages.filter(
          (message) => message.message_id !== action.payload
        );
      },
      clearList: (state) => {
        return initialState
      },
    },
  });
export const {setMessageList, addMessage, deleteMessage, clearList, setChatID, setUserID, deleteMessageReaction, addMessageReaction, setChatImage} = messageSlice.actions
export default messageSlice.reducer