import React, { useEffect } from 'react';
import './chats.css';
import { useDeleteChatMutation, useGetChatMessagesQuery } from '../../services/chatsApi';
import { useDispatch, useSelector } from 'react-redux';
import { setChatID, setChatImage, setMessageList, setUserID } from '../../slices/messagesListSlice';
import { Message } from '../../slices/messagesListSlice';
import { removeChat } from '../../slices/chatListSlice';
import {  useGetUserProfileByNameQuery } from '../../services/profileApi';
import { RootState } from '../../store';

export interface PrivateContactItemProps {
  id: number;
  name: string;
  lastMessage: Message | null;
  imageUrl: string;
}

const Chats: React.FC<PrivateContactItemProps> = ({ id, imageUrl, name, lastMessage,}) => {
  const [deleteChat] = useDeleteChatMutation();
  const {refetch} = useGetChatMessagesQuery({ chatId: id.toString() });
  // Вызываем хук всегда, но делаем запрос только если userName не null
  const { data: apiResponse} = useGetUserProfileByNameQuery({ username: name});
  const chatid = useSelector((state: RootState) => state.message.chat_id);
  useEffect(() => {
    refetch();
  }, []);

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setChatID(""));
    deleteChat({ id: id.toString() });
    dispatch(removeChat(id));
  };

  const dispatch = useDispatch();
  const handleClick = () => {
  
    console.log("message received" + id.toString());
      // Reverse the apiResponse array
    dispatch(setChatID(id.toString()));
    dispatch(setUserID(apiResponse?.id?.toString() || ''));
    dispatch(setChatImage(apiResponse?.user_img_200x200_url));
   
  };


  const formatDate = (dateString: string): string => {
    // Создаём объект Date из строки, предполагаем, что она в UTC
    const date = new Date(dateString);
    const now = new Date();
  
    // Преобразуем в локальное время, если время передано в UTC
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  
    // Разница между текущим временем и переданным
    const diffInMilliseconds = now.getTime() - localDate.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
  
    if (diffInDays < 1) {
      if (diffInHours < 1) {
        return diffInMinutes < 1 ? "только что" : `${diffInMinutes} минут назад`;
      } else {
        return `${diffInHours} ${diffInHours === 1 ? "час" : diffInHours < 5 ? "часа" : "часов"} назад`;
      }
    } else {
      const day = localDate.getDate().toString().padStart(2, '0');
      const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
      return `${day}.${month}`;
    }
  };


  return (
    <div className="contact-item" onClick={handleClick}>
      <img className="contact-item__image" src={imageUrl} />
      <div className="contact-item__details">
        <div className="contact-item__name">{name}</div>
        <div className="contact-item__last-message">
        <span className="contact-lastmessage">
          {lastMessage?.message_text 
            ? lastMessage.message_text 
            : (lastMessage?.attachments != null? <span className="blue-text">Фото</span> : '')}
        </span>
        </div>
        <div className="contact-message-date">
         { lastMessage &&
        <span >{formatDate(lastMessage.message_date)}</span>
         }
        </div>
      </div>
      <button className="contact-item__delete" onClick={handleDelete}></button> {/* Кнопка для открытия модального окна */}
    </div>
  );
};

export default Chats;
