import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PrivateChat } from '../slices/chatListSlice';
import { Message } from '../slices/messagesListSlice';


export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/chat',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token');
      
      if (token) {
        headers.set('Authorization', `Bearer ${token}`); 
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPrivateChats: builder.query<PrivateChat[], { pageIndex: number; pageSize: number }>( {
      query: ({ pageIndex, pageSize }) => ({
        url: '/private', 
        params: {
          pageIndex,
          pageSize,
        }
      }), 
      transformResponse: (response: any) => {
        console.log('Chats', response);  // Логируем ответ API
        return response;
      },
    }),
    deleteChat: builder.mutation<void, {id:string}>( {
      query: ({id}) => ({
        url: '/'+id, 
        method: "DELETE"
      }),
    }),
    getChatMessages: builder.query<Message[], { chatId: string }>( {
      query: ({ chatId }) => ({
        url: `/${chatId}/message`, 
      }),
    }),
    addReactionOnMessage: builder.mutation<void, {chat_id: string, mes_id:number, reac_code: string | null}>( {
      query: ({chat_id, mes_id, reac_code}) => ({
        url: '/'+chat_id+'/reaction', 
        method: "POST",
        body:{
          "message_id": mes_id,
          "reaction_code": reac_code,
        }
      }),
    }),
  
  }),
});

export const { useGetPrivateChatsQuery, useDeleteChatMutation, useGetChatMessagesQuery , useAddReactionOnMessageMutation} = chatApi;
