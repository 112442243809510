import React, { useState } from 'react';
import '../message/message.css';
import { Attachment, Message, Reaction } from '../../slices/messagesListSlice';
import { useSelector } from 'react-redux';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { RootState } from '../../store';

interface MessageProps {
  id: number;
  content: string;
  date: string;
  isUserMessage: boolean;
  avatarUrl: string;
  username: string;
  attachments: Attachment[] | null;
  reactions: Reaction[] | null;
  forward_message: Message | null;
  addReaction: (mes_id: number, reac_code: string) => void;
  deleteReaction: (mes_id: number) => void;
  setAsForward: (mes_id: number) => void;
}

const MessageC: React.FC<MessageProps> = ({
  id,
  content,
  date,
  isUserMessage,
  avatarUrl,
  username,
  attachments,
  reactions,
  forward_message,
  addReaction,
  deleteReaction,
  setAsForward,
}) => {
  const profileData = useSelector((state: RootState) => state.profile);
  const [showPicker, setShowPicker] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const onEmojiClick = (emojiData: EmojiClickData) => {
    const emoji = String.fromCodePoint(parseInt(emojiData.unified, 16)); // Преобразуем hex в строку эмодзи
    addReaction(id, emoji);
    setShowPicker(false); // Скрываем пикер
  };

  const handleDoubleClick = () => {
    const existingReaction = reactions?.find((reaction) => reaction.user_id === profileData.id);
    if (existingReaction) {
      deleteReaction(id); // Удаляем текущую реакцию
    } else {
      setShowPicker(true); // Показываем пикер
    }
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault(); // Предотвращаем стандартное контекстное меню
    setMenuPosition({ x: event.clientX, y: event.clientY }); // Запоминаем позицию клика
    setShowContextMenu(true); // Показываем меню
  };

  const closeContextMenu = () => {
    setShowContextMenu(false); // Закрываем меню
  };



  const formatDate = (dateString: string): string => {
    // Создаём объект Date из строки, предполагаем, что она в UTC
    const date = new Date(dateString);
    const now = new Date();
  
    // Преобразуем в локальное время, если время передано в UTC
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  
    // Разница между текущим временем и переданным
    const diffInMilliseconds = now.getTime() - localDate.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
  
    if (diffInDays < 1) {
      if (diffInHours < 1) {
        return diffInMinutes < 1 ? "только что" : `${diffInMinutes} минут назад`;
      } else {
        return `${diffInHours} ${diffInHours === 1 ? "час" : diffInHours < 5 ? "часа" : "часов"} назад`;
      }
    } else {
      const day = localDate.getDate().toString().padStart(2, '0');
      const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
      return `${day}.${month}`;
    }
  };
  return (
    <div
      className={`message-item ${isUserMessage ? 'user-message' : 'other-message'}`}
      onDoubleClick={handleDoubleClick}
      onContextMenu={handleContextMenu} // Добавляем обработчик ПКМ
    >
      <div className={`message-container ${isUserMessage ? 'message-right' : 'message-left'}`}>
      {forward_message && (
      <div className="forwarded-message">
        <div className="message-header">
          <img src={forward_message.sender.image_url} alt="Avatar" className="message-avatar" />
          <span className="message-username">{forward_message.sender.first_name + " " + forward_message.sender.last_name[0]}</span>
        </div>
        <div className="message-content">
          <p>{forward_message.message_text}</p>
          {forward_message.attachments && forward_message.attachments.length > 0 && (
            <div className="message-attachments">
              {forward_message.attachments.map((attachment) => (
                <div key={attachment.attachment_id} className="message-attachment">
                  {attachment.attachment_type === 'IMAGES' ? (
                  <img
                  src={attachment.attachment_url}
                  alt="Attachment"
                  className="attachment-image"
                  />
                ) : (
                  <span className="attachment-placeholder">
                    {`Attachment #${attachment.attachment_id}`}
                 </span>
                )}
            </div>
        ))}
        </div>
      )}
      {forward_message.reactions && forward_message.reactions.length > 0 && (
        <div className="message-reactions">
          {forward_message.reactions.map((reaction) => (
            <span key={reaction.user_id} className="reaction-icon">
              {reaction.reaction_code}
            </span>
          ))}
        </div>
      )}
    </div>
    <div className="message-date">{formatDate(forward_message.message_date)}</div>
  </div>
)}

       
        <div className="message-header">
          {!isUserMessage && <img src={avatarUrl} alt="Avatar" className="message-avatar" />}
          <span className="message-username">{username}</span>
        </div>
        {}
        <div className="message-content">
          <p>{content}</p>

          {attachments && attachments.length > 0 && (
            <div className="message-attachments">
              {attachments.map((attachment) => (
                <div key={attachment.attachment_id} className="message-attachment">
                  {attachment.attachment_type === 'IMAGES' ? (
                    <img
                      src={attachment.attachment_url}
                      alt="Attachment"
                      className="attachment-image"
                    />
                  ) : (
                    <span className="attachment-placeholder">
                      {`Attachment #${attachment.attachment_id}`}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}

          {reactions && reactions.length > 0 && (
            <div className="message-reactions">
              {reactions.map((reaction) => (
                <span key={reaction.user_id} className="reaction-icon">
                  {reaction.reaction_code}
                </span>
              ))}
            </div>
          )}

          {showPicker && (
            <div className="emoji-picker-container">
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
        <div className="message-date">{formatDate(date)}</div>
      </div>

      {showContextMenu && (
        <div
          className="custom-context-menu"
          style={{ top: menuPosition.y, left: menuPosition.x }}
          onMouseLeave={closeContextMenu} // Закрываем меню при уходе мыши
        >
          <ul>
            <button onClick={() => setAsForward(id)}>Переслать</button>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MessageC;
